import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import FullWidthImage from '../../../components/Images/image-full-width';
import DescriptionSplit from '../../../components/Description/description-split';
import ButtonFit from '../../../components/Buttons/button-fit';
import H4 from '../../../components/Titles/h4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IMG_URL } from '../../../services/api.service';
import * as _ from 'lodash';

export default function ListOfServices({ services, servicesSelectedsaved = [], numberOfGuests = 22, onChange }) {
    const [images, setImages] = useState([]);
    const [servicesSelected, setServicesSelected] = useState(servicesSelectedsaved);
    const { t, i18n } = useTranslation();

    console.log(services);


    useEffect(() => {
        const loadImages = () => {
            // Map over services and directly use the image URLs
            const loadedImages = services.map(service => {
                const imageUrl = `${IMG_URL}${service.image}`;
                return imageUrl; // Directly return the URL
            });

            // Filter out any undefined or null urls if necessary
            setImages(loadedImages.filter(url => url));
        };

        if (services.length > 0) {
            loadImages();
        }
    }, [services]);

    useEffect(() => {
        if (!i18n.language) {
            i18n.changeLanguage('fr');
        }
    }, [i18n]);

    const handleSelectService = useCallback((service, action = "") => {
        const serviceIndex = servicesSelected.findIndex(item => item.slug === service.slug);

        if (!~serviceIndex) {
            const updatedSelection = [...servicesSelected,
            {
                slug: service.slug,
                name: service.name,
                quantity: 1,
                price: service.price,
                VAT: service.VAT,
                isPriceDefined: service.isPriceDefined,
                isPricePerPersonOrForEveryone: service.isPricePerPersonOrForEveryone,
                priceMustBePaidEach: service.priceMustBePaidEach
            }];

            setServicesSelected(updatedSelection);
            onChange(updatedSelection);

        } else {
            const updatedSelection = [...servicesSelected];

            if (action === "add" && service.canBeAddSeveralTimes) {
                updatedSelection[serviceIndex].quantity++;

            } else if (action === "remove") {
                if (updatedSelection[serviceIndex].quantity === 1) {
                    updatedSelection.splice(serviceIndex, 1);

                } else {
                    updatedSelection[serviceIndex].quantity--;
                }
            }

            setServicesSelected(updatedSelection);
            onChange(updatedSelection);
        }

    }, [servicesSelected, onChange]);

    const serviceAlreadySelected = (service) => {
        const serviceIndex = servicesSelected.findIndex(item => item.slug === service.slug);

        return ~serviceIndex ? servicesSelected[serviceIndex].quantity : 0;
    }

    return (
        <>
            {i18n.isInitialized ?
                <>
                    {services.map((service, index) => (
                        <div className='flex flex-col md:flex-row w-full mt-4' key={"service" + index}>
                            <div className='hidden md:block'>
                                <FullWidthImage url={images[index]} alt={"Service " + service.name[i18n.language] || service.name['fr']} className="rounded-xl" customHeight={80} customWidth={80} />
                            </div>

                            <div className='w-full md:w-auto flex md:flex-row md:items-center'>
                                <div className='flex-grow mr-4 md:mx-4'>
                                    <H4 title={service.name[i18n.language] || service.name['fr']} />
                                    <DescriptionSplit description={service.description[i18n.language] || service.description['fr']} className='mt-2' />
                                </div>

                                <div className='text-right whitespace-nowrap mt-0'>
                                    {service.isPriceDefined ? (
                                        <>
                                            {service.price === 0 ? (
                                                <H4 title={t('reservation.services.free')} fontWeight='bold' />
                                            ) : (
                                                <H4 title={service.price + "€"} fontWeight='bold' />
                                            )}
                                        </>
                                    ) : (
                                        <H4 title={t('reservation.services.quotation')} fontWeight='bold' />
                                    )}

                                    {service.textDetailPrice && service.textDetailPrice[i18n.language] && (
                                        <span className='text-xs block mb-2'>{service.textDetailPrice[i18n.language] || service.textDetailPrice['fr']}</span>
                                    )}

                                    {!serviceAlreadySelected(service) > 0 &&
                                        <ButtonFit text={t('inputs.add')} onClick={() => handleSelectService(service)} />
                                    }

                                    {serviceAlreadySelected(service) > 0 &&
                                        <div className='flex items-center gap-2 justify-end '>
                                            <div onClick={() => handleSelectService(service, 'remove')} className='w-8 h-8 border-2 border-main rounded-full p-2 flex items-center justify-center cursor-pointer text-main hover:bg-main hover:text-white transition duration-300'>
                                                <FontAwesomeIcon icon={faMinus} className='text-xl' />
                                            </div>
                                            <span className='font-bold text-xl'>{serviceAlreadySelected(service)}</span>
                                            {serviceAlreadySelected(service) < _.get(service, 'maxQuantity', numberOfGuests) &&
                                                <div onClick={() => handleSelectService(service, 'add')} className={`${service.canBeAddSeveralTimes ? "opacity-100 cursor-pointer" : "opacity-0"} w-8 h-8 border-2 border-main rounded-full p-2 flex items-center justify-center text-main hover:bg-main hover:text-white transition duration-300`}>
                                                    <FontAwesomeIcon icon={faPlus} className='text-xl' />
                                                </div>
                                            }
                                            {serviceAlreadySelected(service) >= _.get(service, 'maxQuantity', numberOfGuests) &&
                                                <div className='w-8' />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                : ""}
        </>
    );
}