// import { useTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Api from '../../../services/api.service';
import Helper from '../../../services/helper.service';

import TitleQuotation from './../components/title-quotation';
import Loading from '../../../components/Loading/loading';
import LogoLong from '../../../components/Logos/logo-long';

import * as _ from 'lodash';
import ListOfServices from '../../Reservation/components/list-of-services';
import Button from '../../../components/Buttons/button';
import PersonnalInfoForm from '../../../components/Forms/personnal-info-form';
import Hr from '../../../components/Style/hr';
import H3 from '../../../components/Titles/h3';
import Validator from '../../../services/validator.service';
import DescriptionSplit from '../../../components/Description/description-split';
import I18nComponentChooseLanguage from '../../../components/Widgets/i18n-choose-language';

export default function QuotationPage() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const [loadedQuotation, setQuotation] = useState(null);

    const [services, setServices] = useState([]);
    const [servicesSelected, setServicesSelected] = useState([]);

    const [displayFormContactDetails, setDisplayFormContactDetails] = useState(false);
    const [personnalInfoFormData, setPersonnalInfoFormData] = useState({
        lastname: "",
        firstname: "",
        email: "",
        phone: ""
    });
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Api.getAllServices();
                const res = response.data;
                if (res.status) {
                    setServices(res.data);

                } else {
                    console.log("Error:", res.error);
                }

                if (id) {
                    const response = await Api.getQuotation(id);
                    const res = response.data;

                    if (res.status) {
                        setPersonnalInfoFormData(res.data.customer);
                        setQuotation(res.data);

                    } else {
                        console.log("Error:", res.error);
                    }
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchData();
        setLoading(false);

    }, [id]);

    const onSelectService = (value) => {
        setServicesSelected(value);
    }

    const displayNumberWithSpace = (number) => {
        return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const getPriceService = (service) => {
        let total = 0;
        if (service.isPriceDefined) {
            total += service.price;

            return displayNumberWithSpace(total);
        }
        return;
    }

    const getSubTotalServices = () => {
        let totalPrice = 0;
        for (const service of servicesSelected) {
            let price = getPriceService(service);
            if (price) {
                totalPrice += (Number(String(getPriceService(service).replace(/\s/g, '')))) * service.quantity;

            } else {
                return "devis";
            }
        }
        return displayNumberWithSpace(totalPrice);
    }

    const clickDisplayFormContactDetails = () => {
        if (!servicesSelected.length) {
            return Helper.toast(t('errors.noServicesSelected'), "error");
        }

        setDisplayFormContactDetails(true);
    }

    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };

    async function sendQuotation() {
        const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
        setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);

        if (Object.keys(tmpErrorsPersonnalInfo).length === 0) {
            const quotation = {
                dates: {
                    startDate: null,
                    endDate: null,
                    creationDate: new Date(),
                    expirationDate: new Date(new Date().setMonth(new Date().getMonth() + 1))
                },
                mainInfos: {
                    status: 'draft',
                    language: i18n.language,
                    numberOfGuests: {
                        adults: 0,
                        childs: 0
                    },
                    comment: _.get(loadedQuotation, 'mainInfos.quotationNumber', null) ? "Lié au devis " + _.get(loadedQuotation, 'mainInfos.quotationNumber', null) : ""
                },
                prices: {
                    accommodationPrice: 0,
                    typeOfAccommodation: 'none',
                    totalPrice: Number(String(getSubTotalServices().replace(/\s/g, ''))),
                    cleaning: 0,
                    discountLongAccommodation: 0,
                    touristTax: 0,
                    enableDeposit: false
                },
                customer: {
                    lastname: personnalInfoFormData.lastname,
                    firstname: personnalInfoFormData.firstname,
                    email: personnalInfoFormData.email,
                    phone: personnalInfoFormData.phone
                },
                guests: [],
                address: {},
                services: servicesSelected.map(service => ({
                    slug: service.slug,
                    name: service.name['fr'],
                    quantity: service.quantity,
                    isPriceDefined: service.isPriceDefined,
                    price: service.isPriceDefined ? Number(String(getPriceService(service).replace(/\s/g, ''))) : 0,
                    VAT: service.VAT,
                    priceMustBePaidEach: service.priceMustBePaidEach
                })),
                history: []
            };

            const response = await Api.saveQuotation(quotation);
            Helper.handleApiResponse(response, t('responses.quotationSend'), t('responses.quotationError'));
            setDisplayFormContactDetails(false);

            const responseEmail = await Api.sendQuotation(response.data.data);
            Helper.handleApiResponse(responseEmail, t('responses.quotationSent'), t('responses.quotationError'));

            if (_.get(response, 'data.status')) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'quotationFormSubmissionSuccess',
                    formType: 'quotation'
                });
            }
        }
    }

    return (
        <div className='bg-backgroundColor'>
            <Helmet>
                <title>{t('pageQuotation.page')}</title>
            </Helmet>

            <header className="flex justify-center pt-8">
                <LogoLong width={250} height={64} />
                <div className='absolute top-[15px] right-[15px]'>
                    <I18nComponentChooseLanguage />
                </div>
            </header>

            <div className='m-auto max-w-5xl pt-14'>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <TitleQuotation title={t('pageQuotation.services.welcomeMessage')} subTitle={t('pageQuotation.services.subTitle')} description={t('pageQuotation.services.description')} fullWidth={true} />

                        <div className="relative w-full mx-auto flex flex-col md:flex-row gap-6 md:gap-10 mb-10">
                            <div className="w-full md:w-3/5 py-6 px-4 md:px-8 h-fit">
                                <ListOfServices services={services} numberOfGuests={_.get(loadedQuotation, 'mainInfos.numberOfGuests.adults', 12) + _.get(loadedQuotation, 'mainInfos.numberOfGuests.childs', 10)} onChange={onSelectService} />
                            </div>

                            <div className="w-full md:w-2/5 h-auto mt-10 flex flex-col">
                                <div className="bg-white rounded-3xl sticky top-10 h-fit flex flex-col justify-end">
                                    <div className="py-6 px-4 md:px-6 rounded-3xl shadow-lg flex flex-col justify-between min-h-[500px]">
                                        <div>
                                            <div className="text-left">
                                                <H3 title={t('pageQuotation.services.summary')} fontWeight='normal' />
                                            </div>

                                            <Hr />

                                            {servicesSelected.length > 0 &&
                                                <>
                                                    {servicesSelected.map((service, index) => (
                                                        <div className="flex justify-between" key={index}>
                                                            <span className="text-sm">
                                                                {service.quantity}x&nbsp;
                                                                {service.name[i18n.language] || service.name.en}
                                                            </span>
                                                            {service.isPriceDefined &&
                                                                <span className="text-sm">{getPriceService(service) === "0" ? `${t('reservation.services.free')}` : getPriceService(service) ? getPriceService(service) + "€" : t('pageQuotation.services.toDefine')}</span>
                                                            }
                                                            {!service.isPriceDefined &&
                                                                <span className="text-sm">{t('pageQuotation.services.toDefine')}</span>
                                                            }
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div>

                                        <div>
                                            <div className="flex mt-20 justify-between">
                                                <H3 title={t('pageQuotation.services.total')} />
                                                <span>{getSubTotalServices() === "devis" ? t('pageQuotation.services.toDefine') : getSubTotalServices() + "€"}</span>
                                            </div>

                                            {!displayFormContactDetails &&
                                                <div className="mt-6 text-center w-full">
                                                    <Button onClick={clickDisplayFormContactDetails} text={t('pageQuotation.services.getQuotation')} className="w-full" />
                                                </div>
                                            }

                                            {displayFormContactDetails &&
                                                <>
                                                    <Hr />
                                                    <DescriptionSplit description={t(t('pageQuotation.services.descriptionContactForm'))} className="text-center mb-8" />
                                                    <PersonnalInfoForm customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} fullWidth={true} />
                                                    <Button onClick={sendQuotation} text={t('pageQuotation.services.validate')} className="mt-8 w-full" />
                                                </>
                                            }

                                            <div className="mt-4 text-xs text-center">
                                                {t('pageQuotation.services.contactFinalizeQuotation')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}